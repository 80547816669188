.servicebg {
    background-color: rgb(1, 1, 76);
}

.homebg {
    background-color: rgb(239, 239, 255);
}

.bgevent {
    background-image: url('../Assest/bgtech.webp');
    /* background-position: center; */
    background-repeat: no-repeat;
    /* background-size: contain; */
    /* width: 100%; */
}

.txtgrd {
    background: linear-gradient(180deg, rgba(255, 22, 139, 1) 51%, rgba(133, 59, 207, 1) 55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.slick-slide>div {
    margin: 0px 10px;
}

.testimonialbg {
    background-color: rgb(203, 204, 228);
}

.contactbg{
    background-image: url('../Assest/ContuctUS.webp');
    background-position: top;
    background-size: cover;
}
.contactrightbg{
    background-image: url('../Assest/contactrightside.webp');
}
.moveright {
    animation: moveright linear infinite 4s;
}

@keyframes moveright {
    0% {
        transform: translate(0, -50%);
    }

    100% {
        transform: translateX(130px) translateY(-50%);
    }
}