.bannerbgservice {
    background-color: rgb(47, 45, 155);
}

.workprocessbg {
    background-image: url('../Assest/bgEffect.png');
    background-position: center;
    background-size: cover;
}

.servicebgprocess {
    background-image: url('../Assest/bgeffect2.webp');
    background-position: center;
    background-size: cover;
}

.textgrdservice {
    background: linear-gradient(84deg, rgba(99, 90, 217, 1) 0%, rgba(33, 155, 228, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
    display: inline-block;
}

.bgindigo {
    background-color: rgb(14, 11, 199);
}

.sectorbg {
    background: rgb(185, 230, 253);
    background: linear-gradient(180deg, rgba(185, 230, 253, 1) 47%, rgba(241, 245, 249, 1) 67%);
}
.sectorimgbg{
    background-image: url('../Assest/serviceBanner.webp');
}
.rotateani {
    animation: rotate 10s linear infinite;
    /* or use 'ease-in-out' instead of 'linear' if you want easing */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(0deg);

    }

    20% {
        transform: rotate(0deg);
    }

    30% {
        transform: rotate(90deg);
    }

    40% {
        transform: rotate(90deg);
    }

    50% {
        transform: rotate(180deg);
    }

    60% {
        transform: rotate(180deg);

    }

    70% {
        transform: rotate(270deg);

    }

    80% {
        transform: rotate(270deg);

    }

    90% {
        transform: rotate(360deg);

    }

    100% {
        transform: rotate(360deg);
    }
}