.searchbar {
    background-image: url('../Assest/searchBack.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.jobbg {
    background-image: url('../Assest/careerBg.webp');
}

.pagination {
    list-style: none;
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    gap: 10px;
    margin-top: 5rem;

}

.pagination .page-num {
    padding: 8px 15px;
    cursor: pointer;
    border-radius: 3px;
    text-decoration: none;
    color: black;
    font-weight: 400;
    /* transition: 1s; */
    border-radius: 20px;
    background-color: white;
    border-radius: 4px;
    border: 2px solid rgb(203, 202, 202);
}


.pagination .page-num:hover {
    background-color: #5751E1;
    color: white;
    transition: 0.8s;
    border: 2px solid #5751E1;


}

.pagination .active {
    background-color: #5751E1;
    color: white;
    font-weight: 500;
    border-radius: 4px;
    border: 2px solid #5751E1;
}

.pagination .next {
    display: none;
}

.pagination .previous {
    display: none;
}


.cont {
    height: 70vh;
    width: 100%;
    overflow: hidden;
}

.cont .div2 {
    width: 100%;
    height: 100%;
    background-color: rgb(23, 238, 77);
    animation: shrink 20s infinite linear;
}

@keyframes shrink {
    0% {
        height: 100%;
        /* transform: translateX(25%); */
    }

    20% {
        height: 60%;
        transform: translate(0%);
    }

    40% {
        transform: translateX(-25%);
    }

    60% {
        transform: translateX(-50%);
    }

    80% {
        transform: translateX(-75%);
    }

    100% {
        transform: translateX(-100%);
    }
}

.cont .div1,
.div3 {
    width: 100%;
    height: 15%;
    background-color: rgb(16, 184, 202);
    animation: shrink2 20s infinite linear;
}

@keyframes shrink2 {
    0% {
        height: 0%;
    }

    20% {
        height: 15%;
        transform: translate(0%);
    }

    40% {
        transform: translateX(-25%);
    }

    60% {
        transform: translateX(-50%);
    }

    80% {
        transform: translateX(-75%);
    }

    100% {
        transform: translateX(-100%);
    }
}