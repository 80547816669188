.btngrd {
    background: rgb(34, 114, 255);
    background: linear-gradient(95deg, rgba(34, 114, 255, 1) 0%, rgba(13, 3, 195, 1) 100%);
}

.hrbggrd {
    background: rgb(255, 255, 255);
    background: linear-gradient(183deg, rgba(255, 255, 255, 1) 0%, rgba(210, 220, 255, 1) 100%);
}
.clientbg{
    background-color: rgb(211,222,255);
}


.floating{
    animation: floating 3s ease-in-out infinite ;
}
@keyframes floating {
    0%{
        transform: translateY(15px);
    }
    50%{
        transform: translateY(0px);
    } 
    100%{
        transform: translateY(15px);
    }
}