* {
    margin: 0;
    box-sizing: border-box;
    padding: 0;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.poppins {
    font-family: "Poppins", sans-serif;
}

.syne {
    font-family: "Syne", sans-serif;
}

.footerclr {
    background-color: rgb(56, 11, 104);
}

.michroma {
    font-family: "Michroma", sans-serif;
}

.scrollbar::-webkit-scrollbar {
    opacity: 0;
}